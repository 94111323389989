import {
  ref, reactive, getCurrentInstance, onMounted
} from 'vue'
import { dateReceipteUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, helpers, maxValue } from '@vuelidate/validators'

export default {
  props: ['dataEdit'],
  emits: ['reloadData', 'closeSidebar'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const id = ref('')
    const title = ref('')
    const dataForm = reactive({
      code: '',
      name: '',
      day: 0,
      isActive: false
    })
    const submitted = ref(false)
    const { dataEdit } = reactive(props)

    const rules = {
      name: {
        required: helpers.withMessage('Nama harus diisi', required)
      },
      code: {
        required: helpers.withMessage('Kode harus diisi', required)
      },
      day: {
        maxValue: helpers.withMessage('Maksimal 10 hari', maxValue(10))
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const definitionDataForm = (val: any) => {
      if (val) {
        title.value = 'Edit Tanggal Penerimaan'
        id.value = val.Id
        dataForm.code = val.Kode
        dataForm.name = val.Nama
        dataForm.day = val.Hari
        dataForm.isActive = val.IsActive
      } else {
        title.value = 'Tambah Tanggal Penerimaan'
        id.value = ''
        dataForm.code = ''
        dataForm.name = ''
        dataForm.day = 0
        dataForm.isActive = false
      }
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const submitProccess = async () => {
      const data = {
        Kode: dataForm.code,
        Nama: dataForm.name,
        Hari: dataForm.day,
        IsActive: dataForm.isActive
      }
      store.dispatch('showLoading')
      const response = await dateReceipteUseCase.submitData(id.value, data)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Tanggal Penerimaan',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await dateReceipteUseCase.deleteData(id.value)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus tanggal penerimaan, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    onMounted(() => {
      definitionDataForm(dataEdit)
    })

    return {
      title,
      dataForm,
      id,
      v$,
      submitted,
      submitData,
      closeSidebar,
      deleteData
    }
  }
}