import {
  ref, onMounted, getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import { dateReceipteUseCase } from '@/domain/usecase'
import DateReceiptForm from '@/views/master/transactions/date-receipt/DateReceiptForm.vue'

export default {
  components: {
    DateReceiptForm
  },
  setup() {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const dataEdit = ref(null)
    const sliderActive = ref(false)
    const data = ref([])
    const op = ref()
    const id = ref('')
    const inputSearch = ref('')
    const filters = {
      search: '',
      page: 0,
      top: 0
    }

    const redirectAdd = () => {
      dataEdit.value = null
      id.value = ''
      sliderActive.value = true
    }

    const redirectEdit = (val: any) => {
      dataEdit.value = val
      id.value = val.Id
      sliderActive.value = true
    }

    const showDropDown = (evt: any, val: any) => {
      dataEdit.value = val
      id.value = val.Id
      op.value.toggle(evt)
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const getAllData = async (search: any = '') => {
      store.dispatch('showLoading')
      if (search.indexOf('+') === 1) {
        search = search.replace('+', '%2B') // %2B => ascii dari +
      }
      filters.search = search
      const response = await dateReceipteUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        data.value = response.result
        console.log(data.value)
      }
      store.dispatch('hideLoading')
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await dateReceipteUseCase.changeIsActive(dataForm)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successChangeStatus, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Tanggal Penerimaan',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await dateReceipteUseCase.deleteData(id.value)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus tanggal penerimaan, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    onMounted(() => {
      getAllData()
    })

    return {
      op,
      data,
      inputSearch,
      dataEdit,
      sliderActive,
      getAllData,
      closeSidebar,
      redirectAdd,
      redirectEdit,
      showDropDown,
      changeIsActive,
      deleteData
    }
  }
}
